import React from "react";
import { useState } from "react";

const SubCasino = ({ header,subCasino, setSubCasino }) => {

  const live = ["SEXY", "EVO", "PT", "PP", "VENUS"];
  const table = ["KINGMAKER", "JILI","PG"];
  const slot = [
    "PP",
    "KINGMAKER",
    "DRAGOONSOFT",
    "PT",
    "JDB",
    "JILI",
  ];
  const fishing = ["JILI",, "JDB","YESBINGO","YL"];
  const egame = ["PP", "JDB"];
  const lottery = ["KINGMAKER", "JILI","YESBINGO"];
  const obj = {
    live: live,
    slot: slot,
    table: table,
    fishing: fishing,
    egame:egame,
    lottery:lottery
  };
  return (
    <>
      <div className="d-flex align-items-center flex-wrap subcasino">
        {obj[header].map((res) => {
          return (
            <span
              onClick={() => setSubCasino(res)}
              className={subCasino === res && "active"}
            >
              {res}
            </span>
          );
        })}
      </div>
    </>
  );
};

export default SubCasino;
